
import { defineComponent } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "widget-1",
  components: {
    Dropdown3
  },
  props: {
    widgetClasses: String,
    widgetColor: String,
    chartHeight: Number
  },
  setup() {
    const items = [
      {
        icon: "media/icons/duotone/Home/Globe.svg",
        title: "Sales",
        description: "100 Regions",
        stats: "$2,5b",
        arrow: "up"
      },
      {
        icon: "media/icons/duotone/Layout/Layout-4-blocks-2.svg",
        title: "Revenue",
        description: "Quarter 2/3",
        stats: "$1,7b",
        arrow: "down"
      },
      {
        icon: "media/icons/duotone/Devices/Watch2.svg",
        title: "Growth",
        description: "80% Rate",
        stats: "$8,8m",
        arrow: "up"
      },
      {
        icon: "media/icons/duotone/General/Clipboard.svg",
        title: "Dispute",
        description: "3090 Refunds",
        stats: "$270m",
        arrow: "down"
      }
    ];

    return {
      items
    };
  }
});
