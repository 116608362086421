<template>
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget1
        widget-classes="card-xl-stretch mb-xl-8"
        widget-color="primary"
      ></MixedWidget1>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget1
        widget-classes="card-xl-stretch mb-xl-8"
        widget-color="danger"
      ></MixedWidget1>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget1
        widget-classes="card-xl-stretch mb-5 mb-xl-8"
        widget-color="success"
      ></MixedWidget1>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Row-->

  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget2
        widget-classes="card-xl-stretch mb-xl-8"
        widget-color="info"
        chart-height="200"
        stroke-color="#4e12c4"
      ></MixedWidget2>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget2
        widget-classes="card-xl-stretch mb-xl-8"
        widget-color="danger"
        chart-height="200"
        stroke-color="#cb1e46"
      ></MixedWidget2>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget2
        widget-classes="card-xl-stretch mb-xl-8"
        widget-color="primary"
        chart-height="200"
        stroke-color="#0078d0"
      ></MixedWidget2>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Row-->

  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget8
        widget-classes="card-xl-stretch mb-xl-8"
        image="media/svg/brand-logos/plurk.svg"
        color="danger"
        title="Monthly Subscription"
        date="Due: 27 Apr 2020"
        progress="75"
      ></MixedWidget8>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget8
        widget-classes="card-xl-stretch mb-xl-8"
        image="media/svg/brand-logos/vimeo.svg"
        color="primary"
        title="Monthly Subscription"
        date="Due: 27 Apr 2020"
        progress="75"
      ></MixedWidget8>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget8
        widget-classes="card-xl-stretch mb-5 mb-xl-8"
        image="media/svg/brand-logos/kickstarter.svg"
        color="success"
        title="Monthly Subscription"
        date="Due: 27 Apr 2020"
        progress="75"
      ></MixedWidget8>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Row-->

  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget9
        widget-classes="card-xl-stretch mb-xl-8"
        image="media/svg/brand-logos/plurk.svg"
        time="7 hours ago"
        title="PitStop - Multiple Email Generator"
        description="Pitstop creates quick email campaigns.<br/>
            We help to strengthen your brand<br/>
            for your every purpose."
      ></MixedWidget9>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget9
        widget-classes="card-xl-stretch mb-xl-8"
        image="media/svg/brand-logos/telegram.svg"
        time="10 days ago"
        title="ReactJS Admin Theme"
        description="Pitstop creates quick email campaigns.<br/>
            We help to strengthen your brand<br/>
            for your every purpose."
      ></MixedWidget9>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget9
        widget-classes="card-xl-stretch mb-5 mb-xl-8"
        image="media/svg/brand-logos/vimeo.svg"
        time="2 weeks ago"
        title="KT.com - High Quality Templates"
        description="Easy to use, incredibly flexible and secure<br/>
            with in-depth documentation that outlines<br/>
            everything for you"
      ></MixedWidget9>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Row-->

  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget3
        widget-classes="card-xl-stretch mb-xl-8"
        chart-height="150"
        chart-color="primary"
      ></MixedWidget3>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget3
        widget-classes="card-xl-stretch mb-xl-8"
        chart-height="150"
        chart-color="danger"
      ></MixedWidget3>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget3
        widget-classes="card-xl-stretch mb-5 mb-xl-8"
        chart-height="150"
        chart-color="success"
      ></MixedWidget3>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Row-->

  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget4
        widget-classes="card-xl-stretch mb-xl-8"
        chart-color="primary"
        chart-height="200"
        btn-color="primary"
      ></MixedWidget4>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget4
        widget-classes="card-xl-stretch mb-xl-8"
        chart-color="success"
        chart-height="200"
        btn-color="success"
      ></MixedWidget4>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget4
        widget-classes="card-xl-stretch mb-5 mb-xl-8"
        chart-color="danger"
        chart-height="200"
        btn-color="danger"
      ></MixedWidget4>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Row-->

  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget5
        widget-classes="card-xl-stretch mb-xl-8"
        chart-color="primary"
        chart-height="150"
      ></MixedWidget5>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget5
        widget-classes="card-xl-stretch mb-xl-8"
        chart-color="success"
        chart-height="150"
      ></MixedWidget5>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget5
        widget-classes="card-xl-stretch mb-5 mb-xl-8"
        chart-color="danger"
        chart-height="150"
      ></MixedWidget5>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Row-->

  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget6
        widget-classes="card-xl-stretch mb-xl-8"
        chart-color="primary"
        chart-height="150"
      ></MixedWidget6>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget6
        widget-classes="card-xl-stretch mb-xl-8"
        chart-color="success"
        chart-height="150"
      ></MixedWidget6>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget6
        widget-classes="card-xl-stretch mb-5 mb-xl-8"
        chart-color="danger"
        chart-height="150"
      ></MixedWidget6>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Row-->

  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget7
        widget-classes="card-xl-stretch mb-xl-8"
        chart-color="info"
        chart-height="150"
      ></MixedWidget7>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget7
        widget-classes="card-xl-stretch mb-xl-8"
        chart-color="warning"
        chart-height="150"
      ></MixedWidget7>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget7
        widget-classes="card-xl-stretch mb-xl-8"
        chart-color="primary"
        chart-height="150"
      ></MixedWidget7>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Row-->

  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget10
        widget-classes="card-xl-stretch mb-xl-8"
        chart-color="info"
        chart-height="200"
      ></MixedWidget10>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget10
        widget-classes="card-xl-stretch mb-xl-8"
        chart-color="warning"
        chart-height="200"
      ></MixedWidget10>
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-xl-4">
      <MixedWidget10
        widget-classes="card-xl-stretch mb-5 mb-xl-8"
        chart-color="primary"
        chart-height="200"
      ></MixedWidget10>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Row-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import MixedWidget1 from "@/components/widgets/mixed/Widget1.vue";
import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue";
import MixedWidget3 from "@/components/widgets/mixed/Widget3.vue";
import MixedWidget4 from "@/components/widgets/mixed/Widget4.vue";
import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";
import MixedWidget6 from "@/components/widgets/mixed/Widget6.vue";
import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
import MixedWidget8 from "@/components/widgets/mixed/Widget8.vue";
import MixedWidget9 from "@/components/widgets/mixed/Widget9.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "widgets-mixed",
  components: {
    MixedWidget1,
    MixedWidget2,
    MixedWidget3,
    MixedWidget4,
    MixedWidget5,
    MixedWidget6,
    MixedWidget7,
    MixedWidget8,
    MixedWidget9,
    MixedWidget10
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Mixed", ["Widgets"]);

      MenuComponent.reinitialization();
    });
  }
});
</script>
