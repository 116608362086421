
import { defineComponent } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";

export default defineComponent({
  name: "widget-1",
  components: {
    Dropdown2
  },
  props: {
    widgetClasses: String,
    color: String,
    image: String,
    title: String,
    date: String,
    progress: String
  }
});
